import React, { useState } from 'react';
import { 
  Box, 
  Heading, 
  Text, 
  FormControl, 
  FormLabel, 
  Input, 
  Button, 
  VStack, 
  useToast,
  Card, 
  CardHeader, 
  CardBody,
  FormErrorMessage,
  useDisclosure,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Navbar from '../components/Navbar';
import api from '../api';

const UploadForm = ({ onSubmit, isLoading }) => {
  const { 
    register, 
    handleSubmit,
    formState: { errors }
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={4}>
        <FormControl isInvalid={errors.file}>
          <FormLabel>Excel File</FormLabel>
          <Input
            type="file"
            accept=".xlsx,.xls"
            {...register('file', { 
              required: 'Please select a file',
              validate: {
                isExcel: (files) => {
                  const file = files?.[0];
                  if (!file) return true;
                  return (
                    file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                    file.type === 'application/vnd.ms-excel' ||
                    'Please upload only Excel files'
                  );
                }
              }
            })}
          />
          <FormErrorMessage>
            {errors.file && errors.file.message}
          </FormErrorMessage>
        </FormControl>
        <Button 
          type="submit" 
          colorScheme="teal" 
          isLoading={isLoading}
          loadingText="Uploading..."
          width="full"
        >
          Upload
        </Button>
      </VStack>
    </form>
  );
};

const UploadOrders = () => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen: isSuccess, onOpen: onSuccess, onClose: onCloseSuccess } = useDisclosure();

  const handleUpload = async (data) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', data.file[0]);

    try {
      const response = await api.post('/insurance/upload_excel', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        onSuccess();
        toast({
          title: 'Upload Successful',
          description: 'The file has been uploaded successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        
        // Redirect after a short delay to allow the user to see the success message
        setTimeout(() => {
          navigate('/insurance-orders');
        }, 2000);
      }
    } catch (error) {
      toast({
        title: 'Upload Failed',
        description: error.response?.data?.detail || 'An error occurred during the upload.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Navbar />
      <Box p={5}>
        {isSuccess && (
          <Alert status="success" mb={5}>
            <AlertIcon />
            Upload successful! Redirecting to orders page...
          </Alert>
        )}
        
        <Card w="100%" maxW="800px" mx="auto">
          <CardHeader>
            <Heading as="h1" fontSize="lg">Upload Insurance</Heading>
          </CardHeader>
          <CardBody>
            <VStack spacing={4} align="stretch">
              <Text>
                Upload an Excel file containing insurance orders. 
                Please ensure your file follows the required format.
              </Text>
              
              <UploadForm 
                onSubmit={handleUpload}
                isLoading={isLoading}
              />
            </VStack>
          </CardBody>
        </Card>
      </Box>
    </Box>
  );
};

export default UploadOrders;
