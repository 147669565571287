import React from 'react';
import { Box, Card, CardBody, Center, Spinner } from '@chakra-ui/react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: { position: 'top' },
    title: {
      display: true,
      text: 'Daily Orders'
    }
  }
};

const DashboardChart = ({ data, isLoading }) => {
  const chartData = {
    labels: data.map(item => `${item._id.year}-${item._id.month}-${item._id.day}`),
    datasets: [{
      label: 'Orders',
      data: data.map(item => item.count),
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
    }]
  };

  return (
    <Card mt={5}>
      <CardBody>
        <Box w="100%" h="400px">
          {isLoading ? (
            <Center h="100%">
              <Spinner size="xl" />
            </Center>
          ) : (
            <Bar data={chartData} options={chartOptions} />
          )}
        </Box>
      </CardBody>
    </Card>
  );
};

export default DashboardChart; 