import React from 'react';
import { Card, CardHeader, CardBody, Center, Heading, Text, Spinner } from '@chakra-ui/react';

const StatCard = ({ title, value, isLoading }) => (
  <Card>
    <CardHeader>{title}</CardHeader>
    <CardBody>
      <Center>
        {isLoading ? (
          <Spinner size="xl" />
        ) : (
          <Heading size="2xl">{value}</Heading>
        )}
      </Center>
      <Center>
        <Text mt={5}>Insurance Orders</Text>
      </Center>
    </CardBody>
  </Card>
);

export default StatCard; 