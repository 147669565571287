import React, { useEffect, useState, useMemo } from 'react';
import { Box, Card, CardBody, CardHeader, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Navbar from '../components/Navbar';
import HistoryFilters from '../components/HistoryFilters';
import HistoryTable from '../components/HistoryTable';
import Pagination from '../components/Pagination';
import { useHistoryColumns } from '../hooks/useHistoryColumns';
import { formatToLocalISOString } from '../utils/dateUtils';
import api from '../api';
import { logout } from '../auth';

const InsuranceHistory = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState({
    nameFilter: '',
    startDate: null,
    endDate: null,
  });

  const handleDownloadReport = async (trxId, filename) => {
    setLoading(true);
    try {
      const response = await api.get('/insurance/', { params: { trxid: trxId } });
      const data = response.data.orders;

      const worksheetData = data.map((row, index) => ({
        'No.': index + 1,
        'No Kontrak': row.no_kontrak,
        'Tanggal Akad': row.tanggal_akad,
        'Nama Lengkap': row.nama_lengkap,
        'No KTP': row.no_ktp,
        'Jenis Kelamin': row.jenis_kelamin,
        'Tempat Lahir': row.tempat_lahir,
        'Tanggal Lahir': row.tanggal_lahir,
        'No HP': row.no_hp,
        'Nilai Pinjaman': row.nilai_pinjaman,
        'Nilai Uang Pertanggungan': row.nilai_uang_pertanggungan,
        'Tenor': row.tenor,
        'No Sertifikat': row.no_sertifikat,
        'Tanggal Validity': row.tanggal_validity,
        'URL PDF': row.url_pdf,
      }));

      const worksheet = XLSX.utils.json_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
      XLSX.writeFile(workbook, `Report-${filename}.xlsx`);
    } catch (error) {
      console.error('Error downloading report', error);
    } finally {
      setLoading(false);
    }
  };

  const columns = useHistoryColumns(handleDownloadReport);
  const data = useMemo(() => orders, [orders]);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const params = {
        skip: currentPage * pageSize,
        limit: pageSize,
        name: filters.nameFilter || undefined,
        start_date: filters.startDate ? formatToLocalISOString(filters.startDate) : undefined,
        end_date: filters.endDate ? formatToLocalISOString(filters.endDate) : undefined,
      };
      const response = await api.get('/history/', { params });

      setOrders(response.data.orders);
      setPageCount(Math.ceil(response.data.total / pageSize));
    } catch (error) {
      console.error('Error fetching orders', error);
      logout();
      navigate('/login');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [currentPage, pageSize, filters]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <Box>
      <Navbar />
      <Box p={5}>
        <Card>
          <CardHeader>
            <Heading as="h1" fontSize="lg">Filters</Heading>
          </CardHeader>
          <CardBody>
            <HistoryFilters filters={filters} setFilters={setFilters} />
          </CardBody>
        </Card>

        <Card mt={5}>
          <CardHeader>
            <Heading as="h1" fontSize="lg">Insurance History</Heading>
          </CardHeader>
          <CardBody>
            <HistoryTable
              columns={columns}
              data={data}
              loading={loading}
            />
            <Pagination
              currentPage={currentPage}
              pageCount={pageCount}
              pageSize={pageSize}
              setPageSize={setPageSize}
              onPageChange={handlePageChange}
              canNextPage={currentPage < pageCount - 1}
              canPreviousPage={currentPage > 0}
            />
          </CardBody>
        </Card>
      </Box>
    </Box>
  );
};

export default InsuranceHistory;
