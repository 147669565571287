import React from 'react';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';

export const useHistoryColumns = (onDownload) => {
  return React.useMemo(
    () => [
      {
        Header: 'Trx ID',
        accessor: 'trx_id',
      },
      {
        Header: 'Filename',
        accessor: 'filename',
      },
      {
        Header: 'Uploaded By',
        accessor: 'username',
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }) => (
          <Tooltip label="Download Report">
            <IconButton
              icon={<DownloadIcon />}
              onClick={() => onDownload(row.original.trx_id, row.original.filename)}
              colorScheme="blue"
              aria-label="Download Excel Report"
            />
          </Tooltip>
        ),
      },
    ],
    [onDownload]
  );
}; 