import React from 'react';
import {
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Flex,
  VStack,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';

const FilterForm = ({ filters, setFilters }) => (
  <VStack spacing={4} mt={5} mb={5} align="flex-start">
    <SimpleGrid columns={{ base: 1, md: 5 }} spacing={4}>
      <FormControl>
        <FormLabel>Transaction ID</FormLabel>
        <Input
          value={filters.trxId}
          onChange={(e) => setFilters({ ...filters, trxId: e.target.value })}
          placeholder="Filter by transaction ID"
        />
      </FormControl>

      <FormControl>
        <FormLabel>Name</FormLabel>
        <Input
          value={filters.name}
          onChange={(e) => setFilters({ ...filters, name: e.target.value })}
          placeholder="Filter by name"
        />
      </FormControl>

      <FormControl>
        <FormLabel>Contract No</FormLabel>
        <Input
          value={filters.noKontrak}
          onChange={(e) => setFilters({ ...filters, noKontrak: e.target.value })}
          placeholder="Filter by contract number"
        />
      </FormControl>

      <FormControl>
        <FormLabel>ID Card No</FormLabel>
        <Input
          value={filters.noKtp}
          onChange={(e) => setFilters({ ...filters, noKtp: e.target.value })}
          placeholder="Filter by ID card number"
        />
      </FormControl>

      <FormControl>
        <FormLabel>Date Aggreement</FormLabel>
        <Flex direction={{ base: 'column', md: 'row' }} gap={4}>
          <DatePicker
            selected={filters.startDate}
            onChange={(date) => setFilters({ ...filters, startDate: date })}
            selectsStart
            startDate={filters.startDate}
            endDate={filters.endDate}
            placeholderText="Start Date"
            dateFormat="yyyy-MM-dd"
            customInput={<Input />}
          />
          <DatePicker
            selected={filters.endDate}
            onChange={(date) => setFilters({ ...filters, endDate: date })}
            selectsEnd
            startDate={filters.startDate}
            endDate={filters.endDate}
            minDate={filters.startDate}
            placeholderText="End Date"
            dateFormat="yyyy-MM-dd"
            customInput={<Input />}
          />
        </Flex>
      </FormControl>
    </SimpleGrid>
  </VStack>
);

export default FilterForm; 