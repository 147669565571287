
import api from './api';

export const login = async (username, password) => {
    // send a POST request using axios form data
    console.log('username', username);

    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    const response = await api.post('/login', formData, {

        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    console.log(JSON.stringify(response.data));
    if (response.data.access_token) {
        localStorage.setItem('user', JSON.stringify(response.data));
    }
    return response.data;
};

export const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem('user'));
};

export const logout = () => {
    localStorage.removeItem('user');
};
