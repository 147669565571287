import React from 'react';
import {
  Flex,
  IconButton,
  Text,
  Tooltip,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
} from '@chakra-ui/react';
import {
  ArrowRightIcon,
  ArrowLeftIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from '@chakra-ui/icons';

const Pagination = ({
  currentPage,
  pageCount,
  pageSize,
  setPageSize,
  onPageChange,
  canNextPage,
  canPreviousPage,
}) => (
  <Flex
    direction={{ base: 'column', md: 'row' }}
    justifyContent="space-between"
    m={4}
    alignItems="center"
  >
    <Flex mb={{ base: 4, md: 0 }}>
      <Tooltip label="First Page">
        <IconButton
          onClick={() => onPageChange(0)}
          isDisabled={!canPreviousPage}
          icon={<ArrowLeftIcon h={3} w={3} />}
          mr={4}
        />
      </Tooltip>
      <Tooltip label="Previous Page">
        <IconButton
          onClick={() => onPageChange(currentPage - 1)}
          isDisabled={!canPreviousPage}
          icon={<ChevronLeftIcon h={6} w={6} />}
        />
      </Tooltip>
    </Flex>

    <Flex alignItems="center">
      <Text flexShrink="0" mr={8}>
        Page{' '}
        <Text fontWeight="bold" as="span">
          {currentPage + 1}
        </Text>{' '}
        of{' '}
        <Text fontWeight="bold" as="span">
          {pageCount}
        </Text>
      </Text>
      <Text flexShrink="0">Go to page:</Text>{' '}
      <NumberInput
        ml={2}
        mr={8}
        w={28}
        min={1}
        max={pageCount}
        value={currentPage + 1}
        onChange={(value) => onPageChange(value ? value - 1 : 0)}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <Select
        w={32}
        value={pageSize}
        onChange={(e) => setPageSize(Number(e.target.value))}
      >
        {[10, 20, 30, 40, 50].map((size) => (
          <option key={size} value={size}>
            Show {size}
          </option>
        ))}
      </Select>
    </Flex>

    <Flex mb={{ base: 4, md: 0 }}>
      <Tooltip label="Next Page">
        <IconButton
          onClick={() => onPageChange(currentPage + 1)}
          isDisabled={!canNextPage}
          icon={<ChevronRightIcon h={6} w={6} />}
        />
      </Tooltip>
      <Tooltip label="Last Page">
        <IconButton
          onClick={() => onPageChange(pageCount - 1)}
          isDisabled={!canNextPage}
          icon={<ArrowRightIcon h={3} w={3} />}
          ml={4}
        />
      </Tooltip>
    </Flex>
  </Flex>
);

export default Pagination; 