import React, { useEffect, useState } from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import StatCard from '../components/StatCard';
import DashboardChart from '../components/DashboardChart';
import api from '../api';
import { logout } from '../auth';

const Dashboard = () => {
  const navigate = useNavigate();
  const [summaryData, setSummaryData] = useState({
    today: 0,
    month: 0,
    year: 0,
    countsByDay: []
  });
  const [isLoading, setIsLoading] = useState(true);

  const fetchDashboardData = async () => {
    try {
      const [summaryResponse, countsByDayResponse] = await Promise.all([
        api.get('/summary/count_today'),
        api.get('/summary/count_by_day')
      ]);

      setSummaryData({
        today: summaryResponse.data.today,
        month: summaryResponse.data.month,
        year: summaryResponse.data.year,
        countsByDay: countsByDayResponse.data
      });
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      logout();
      navigate('/login');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  return (
    <Box>
      <Navbar />
      <Box p={5}>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
          <StatCard 
            title="Today" 
            value={summaryData.today} 
            isLoading={isLoading} 
          />
          <StatCard 
            title="Month to Date" 
            value={summaryData.month} 
            isLoading={isLoading} 
          />
          <StatCard 
            title="Year to Date" 
            value={summaryData.year} 
            isLoading={isLoading} 
          />
        </SimpleGrid>

        <DashboardChart 
          data={summaryData.countsByDay} 
          isLoading={isLoading} 
        />
      </Box>
    </Box>
  );
};

export default Dashboard;
