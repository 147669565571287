import React from 'react';
import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    FormControl,
    FormLabel,
    Input,
    VStack,
    useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { login } from '../auth';

const LoginPage = () => {
    const {
        register,
        handleSubmit,
        formState: { isSubmitting, errors }
    } = useForm();
    const navigate = useNavigate();
    const toast = useToast();

    const onSubmit = async (data) => {
        try {
            const response = await login(data.username, data.password);

            if (response.access_token) {
                toast({
                    title: 'Welcome back!',
                    status: 'success',
                    duration: 3000,
                });
                navigate('/dashboard');
            }
        } catch (error) {
            toast({
                title: 'Login failed',
                description: 'Please check your credentials',
                status: 'error',
                duration: 3000,
            });
        }
    };

    return (
        <Box
            p={5}
            display="flex"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
            bg="gray.50"
        >
            <Card w="100%" maxW="md" boxShadow="lg">
                <CardHeader textAlign="center" pb={0}>
                    <Box fontSize="2xl" fontWeight="bold" color="teal.500">
                        Insurance Dashboard
                    </Box>
                </CardHeader>

                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <VStack spacing={4}>
                            <FormControl isInvalid={errors.username}>
                                <FormLabel>Username</FormLabel>
                                <Input
                                    {...register('username', {
                                        required: 'Username is required',
                                    })}
                                    placeholder="Enter your username"
                                />
                            </FormControl>

                            <FormControl isInvalid={errors.password}>
                                <FormLabel>Password</FormLabel>
                                <Input
                                    type="password"
                                    {...register('password', {
                                        required: 'Password is required',
                                        minLength: { value: 6, message: 'Minimum 6 characters' }
                                    })}
                                    placeholder="Enter your password"
                                />
                            </FormControl>

                            <Button
                                type="submit"
                                colorScheme="teal"
                                width="full"
                                isLoading={isSubmitting}
                                loadingText="Signing in..."
                            >
                                Sign In
                            </Button>
                        </VStack>
                    </form>
                </CardBody>
            </Card>
        </Box>
    );
};

export default LoginPage;
