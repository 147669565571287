import React from 'react';
import {
    Box,
    Flex,
    Button,
    Stack,
    IconButton,
    useDisclosure,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    VStack,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { logout } from '../auth';

const Navbar = () => {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const NavLinks = ({ isMobile = false }) => (
        <Stack direction={isMobile ? "column" : "row"} spacing={isMobile ? 4 : 7}>
            <Button 
                as={RouterLink} 
                to="/dashboard" 
                color="teal.50" 
                variant="link"
                w={isMobile ? "full" : "auto"}
            >
                Dashboard
            </Button>
            <Button 
                as={RouterLink} 
                to="/insurance-orders" 
                color="teal.50" 
                variant="link"
                w={isMobile ? "full" : "auto"}
            >
                Insurance Data
            </Button>
            <Button 
                as={RouterLink} 
                to="/upload-orders" 
                color="teal.50" 
                variant="link"
                w={isMobile ? "full" : "auto"}
            >
                Upload Insurance
            </Button>
            <Button 
                as={RouterLink} 
                to="/insurance-history" 
                color="teal.50" 
                variant="link"
                w={isMobile ? "full" : "auto"}
            >
                Upload History
            </Button>
            <Button 
                onClick={handleLogout} 
                color="teal.50" 
                variant={isMobile ? "link" : "outline"}
                w={isMobile ? "full" : "auto"}
            >
                Logout
            </Button>
        </Stack>
    );

    return (
        <Box bg="black" px={4}>
            <Flex h={16} alignItems="center" justifyContent="space-between">
                <Box color="teal.50"></Box>

                {/* Desktop Navigation */}
                <Box display={{ base: 'none', md: 'block' }}>
                    <NavLinks />
                </Box>

                {/* Mobile Navigation Button */}
                <IconButton
                    display={{ base: 'flex', md: 'none' }}
                    onClick={onOpen}
                    icon={<HamburgerIcon />}
                    variant="outline"
                    color="teal.50"
                    aria-label="Open menu"
                    size="md"
                />

                {/* Mobile Navigation Drawer */}
                <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
                    <DrawerOverlay />
                    <DrawerContent bg="black">
                        <DrawerCloseButton color="teal.50" />
                        <DrawerHeader color="teal.50">Menu</DrawerHeader>
                        <DrawerBody>
                            <VStack align="stretch" spacing={4}>
                                <NavLinks isMobile={true} />
                            </VStack>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            </Flex>
        </Box>
    );
};

export default Navbar;
