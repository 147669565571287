import React from 'react';
import { ChakraProvider, Box } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import InsuranceOrders from './pages/InsuranceOrders';
import InsuranceHistory from './pages/InsuranceHistory';
import UploadOrders from './pages/UploadOrders';
import Summary from './pages/Summary';
import ProtectedRoute from './components/ProtectedRoute';

const App = () => {
  return (
    <Box bg="gray.100" minHeight="100vh">
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
          <Route path="/insurance-orders" element={<ProtectedRoute component={InsuranceOrders} />} />
          <Route path="/insurance-history" element={<ProtectedRoute component={InsuranceHistory} />} />
          <Route path="/upload-orders" element={<ProtectedRoute component={UploadOrders} />} />
          {/* <Route path="/summary" element={<ProtectedRoute component={Summary} />} /> */}
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </Box >
  );
};

export default App;
