// api.js
import axios from 'axios';
import { getCurrentUser } from './auth';
import config from './config/config';

const API_URL = config.apiUrl;

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

instance.interceptors.request.use((config) => {
  const user = getCurrentUser();
  if (user && user.access_token) {
    config.headers['Authorization'] = `Bearer ${user.access_token}`;
  }
  return config;
});

export default instance;
