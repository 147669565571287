import React from 'react';
import {
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Flex,
  VStack,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const HistoryFilters = ({ filters, setFilters }) => (
  <VStack spacing={4} mt={5} mb={5} align="flex-start">
    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
      <FormControl>
        <FormLabel>Filename</FormLabel>
        <Input
          value={filters.nameFilter}
          onChange={(e) => setFilters({ ...filters, nameFilter: e.target.value })}
          placeholder="Filter by filename"
        />
      </FormControl>
      <FormControl>
        <FormLabel>Created At</FormLabel>
        <Flex direction={{ base: 'column', md: 'row' }} gap={4}>
          <DatePicker
            selected={filters.startDate}
            onChange={(date) => setFilters({ ...filters, startDate: date })}
            selectsStart
            startDate={filters.startDate}
            endDate={filters.endDate}
            placeholderText="Start Date"
            dateFormat="yyyy-MM-dd"
            customInput={<Input />}
          />
          <DatePicker
            selected={filters.endDate}
            onChange={(date) => setFilters({ ...filters, endDate: date })}
            selectsEnd
            startDate={filters.startDate}
            endDate={filters.endDate}
            minDate={filters.startDate}
            placeholderText="End Date"
            dateFormat="yyyy-MM-dd"
            customInput={<Input />}
          />
        </Flex>
      </FormControl>
    </SimpleGrid>
  </VStack>
);

export default HistoryFilters; 