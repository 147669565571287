import React, { useEffect, useState } from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import { Bar } from 'react-chartjs-2';
import Navbar from '../components/Navbar';
import api from '../api';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Summary = () => {
    const [todayCount, setTodayCount] = useState(0);
    const [countsByDay, setCountsByDay] = useState([]);

    useEffect(() => {
        const fetchSummary = async () => {
            const responseToday = await api.get('/summary/count_today');
            setTodayCount(responseToday.data.count);

            const responseByDay = await api.get('/summary/count_by_day');
            setCountsByDay(responseByDay.data);
        };
        fetchSummary();
    }, []);

    const data = {
        labels: countsByDay.map(item => `${item._id.year}-${item._id.month}-${item._id.day}`),
        datasets: [
            {
                label: 'Orders',
                data: countsByDay.map(item => item.count),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    return (
        <Box>
            <Navbar />
            <Box p={5}>
                <Heading>Summary</Heading>
                <Text mt={5}>Orders Today: {todayCount}</Text>
                <Box mt={5}>
                    <Bar data={data} />
                </Box>
            </Box>
        </Box>
    );
};

export default Summary;
