import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { getCurrentUser } from '../auth';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const user = getCurrentUser();
    return user ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
