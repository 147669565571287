import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Spinner,
  Center,
} from '@chakra-ui/react';
import { useTable } from 'react-table';

const InsuranceTable = ({ columns, data, loading }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  if (loading) {
    return (
      <Center p={8}>
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <Box overflowX="auto">
      <Table {...getTableProps()} mt={5} fontSize="sm">
        <Thead>
          {headerGroups.map(headerGroup => {
            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
            return (
              <Tr key={key} {...headerGroupProps}>
                {headerGroup.headers.map(column => {
                  const { key: columnKey, ...columnProps } = column.getHeaderProps();
                  return (
                    <Th key={columnKey} {...columnProps} fontSize="xs">
                      {column.render('Header')}
                    </Th>
                  );
                })}
              </Tr>
            );
          })}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            const { key, ...rowProps } = row.getRowProps();
            return (
              <Tr key={key} {...rowProps}>
                {row.cells.map(cell => {
                  const { key: cellKey, ...cellProps } = cell.getCellProps();
                  return (
                    <Td key={cellKey} {...cellProps} fontSize="sm">
                      {cell.render('Cell')}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

export default InsuranceTable; 