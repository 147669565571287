import React from 'react';
import { Button } from '@chakra-ui/react';

export const useTableColumns = () => {
    return React.useMemo(
        () => [
            {
                Header: 'Created At',
                accessor: 'created_at',
                Cell: ({ value }) => new Date(value).toLocaleString(),
            },
            {
                Header: "Transaction ID",
                accessor: "trx_id",
            },
            {
                Header: 'Contract No',
                accessor: 'no_kontrak',
            },
            {
                Header: 'Date of Agreement',
                accessor: 'tanggal_akad',
                Cell: ({ value }) => new Date(value).toLocaleDateString(),
            },
            {
                Header: 'Full Name',
                accessor: 'nama_lengkap',
            },
            {
                Header: 'ID Card No',
                accessor: 'no_ktp',
            },
            {
                Header: 'Gender',
                accessor: 'jenis_kelamin',
            },
            {
                Header: 'Date of Birth',
                accessor: 'tanggal_lahir',
                Cell: ({ value }) => new Date(value).toLocaleDateString(),
            },
            {
                Header: 'Place of Birth',
                accessor: 'tempat_lahir',
            },
            {
                Header: 'Phone No',
                accessor: 'no_hp',
            },
            {
                Header: 'Loan Amount',
                accessor: 'nilai_pinjaman',
                Cell: ({ value }) => value.toLocaleString(),
            },
            {
                Header: 'Coverage Amount',
                accessor: 'nilai_uang_pertanggungan',
                Cell: ({ value }) => value.toLocaleString(),
            },
            {
                Header: 'Tenure (months)',
                accessor: 'tenor',
            },
            {
                Header: 'Insurance Submit',
                accessor: 'sendDataInsurance',
                Cell: ({ value }) => (value ? 'Yes' : 'No'),
            },
            // New column for Data Submit (sendDataProvis)
            {
                Header: 'Data Submit',
                accessor: 'sendDataProvis',
                Cell: ({ value }) => (value ? 'Yes' : 'No'),
            },
            {
                Header: 'No Sertificate',
                accessor: 'no_sertifikat',
            },
            {
                Header: 'Validity Date',
                accessor: 'tanggal_validity',
            },
            // New column for PDF link (url_pdf)
            {
                Header: 'Pdf',
                accessor: 'url_pdf',
                Cell: ({ value }) => (
                    <Button
                        as="a"
                        href={value}
                        target="_blank"
                        rel="noopener noreferrer"
                        colorScheme="blue"
                        variant="outline"
                        size="sm"
                    >
                        Open PDF
                    </Button>
                ),
            },
        ],
        []
    );
}; 